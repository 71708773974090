<template>
    <div>
        <breadcrumbs
            :settings="settingsBreadcrumbs"
        />
        <radius-loader v-if="loading" />
        <section
            v-else
            class="wrapper wrapper--xs"
        >
            <div class="my-ticket-details">
                <div>
                    <support-card
                        mode="solo"
                        :ticket="ticket"
                        :subject="ticket.subject"
                        :status="ticket.status_support"
                        :order-id="ticket.orderid"
                        :ticket-id="ticket.ticketid"
                        :type="ticket.ticket_type"
                        :resolution="ticket.ticket_resolution"
                        :category="ticket.ticket_category"
                        :unread-messages="unreadMessages"
                        is-open
                        :create-date="ticket.created_at"
                        :long-names-category="ticket.long_names && ticket.long_names.category"
                        :long-names-resolution="ticket.long_names && ticket.long_names.resolution"
                        :long-names-type="ticket.long_names && ticket.long_names.type"
                        :last-message="ticket.last_message"
                    />

                    <support-card-body
                        :ticket="ticket"
                        :bubles="unreadMessages"
                        :messages="messages"
                        @markAsRead="markAsRead"
                    />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'
import { eventBus } from '@/helpers/event-bus'
import Api from '@/helpers/api/index.js'

// Components
import SupportCard from '@/components/account/Cards/SupportCard/SupportCard';
import RadiusLoader from '@/components/common/RadiusLoader'
import SupportCardBody from '@/components/account/Cards/SupportCard/SupportCardBody.vue'
import Breadcrumbs from '@/components/Breadcrumbs';

import {
    getSupportMessages
} from '@/services/support'

export default {
    components: {
        SupportCard,
        RadiusLoader,
        SupportCardBody,
        Breadcrumbs
    },
    metaInfo() {
        return {
            title: `${this.ticketid} | Ticket Details`
        }
    },
    data: () => ({
        loading: true,
        ticket: {},
        showPopup: false,
        unreadMessages: 0,
        messages: []
    }),
    computed: {
        ...mapGetters('client', [
            'getterIsAuth'
        ]),
        ticketid() {
            return this.$route.params.id
        },
        settingsBreadcrumbs() {
            const { breadcrumbs } = this.$route.meta
            return {
                title: breadcrumbs.name,
                excerpt: breadcrumbs.excerpt,
                additionalClass: breadcrumbs.additionalClass
            }
        }
    },
    async activated() {
        await this.getTicket()
        await this.getMessages()
    },
    methods: {
        async getTicket() {
            const isAuth = this.getterIsAuth ? 'auth' : 'guest'
            try {
                this.loading = true
                const { data } = await Api.get(`/api/support/${isAuth}/fetch-ticket`, removeEmptyParamsFromRequest({ ticketid: this.ticketid, hash: this.$route.query.hash }))
                this.ticket = data
                this.unreadMessages = this.ticket.counters?.unread_messages_count || 0
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        async getMessages() {
            try {
                this.loading = true
                const data = await getSupportMessages({
                    ticketid: this.ticketid,
                    hash: this.$route.query.hash
                }, this.getterIsAuth)
                this.messages = data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.loading = false
            }
        },
        async markAsRead(payload) {
            const isAuth = this.getterIsAuth ? 'auth' : 'guest'
            await Api.put(`/api/support/${isAuth}/mark-as-read`, { ...payload, hash: this.$route.query.hash })
                .then(() => {
                    setTimeout(() => {
                        this.$emit('markAsRead', {
                            unreadMessages: this.unreadMessages,
                            ticketid: payload.ticketid
                        })
                    }, 10000)
                })
            this.unreadMessages = 0
        }
    }
}
</script>

<style lang="scss">
.order .tab-wrapper .tab {
    display: inline;
}

.create-support-ticket {
    transform: translateY(-150%);
    animation: ani 1s forwards;
}

.my-ticket-details {
    margin-bottom: 110px;
    .chat-wrapper{
        max-height: initial !important;
        overflow: auto;
    }
}

@keyframes ani {
    0% {transform: translateY(-10%);}
    100% {transform: translateY(0);}
}
</style>
